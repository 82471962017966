







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({ components: {} })
export default class WeeklyCommissionedTable extends Vue {
    @Prop() weekly_by_contractor;
    @Prop() noData;
    @Prop() device_types;
    @Prop() week_dates;
    @Prop() contractors;
    @Prop() message;

    header_color = '#EC1163';
    rows_color = ['#F8CCD3', '#FBE7EA'];
    headers = ['Contractor', 'Area', 'Device Type', 'Total'];
    tableData = [];
    loading = true;

    mounted(){
        this.loading = true;
        const dates = this.week_dates.reverse().map((date) => moment(date).format('DD/MM/YYYY'));
        this.headers = this.headers.concat(dates);
        if (!this.noData) this.generateTableData();
        this.loading = false;
    }

    generateTableData(){
        [...this.weekly_by_contractor.entries()].forEach(([contractor, data]) => {
            Object.entries(this.device_types).forEach(([type, title], index) => {
                const row = {
                    contractor_name: this.contractors[contractor].contractor_name,
                    area: this.contractors[contractor].area.join(),
                    type: title,
                    ...data[type]
                };
                this.tableData.push(row);
            });
        });
    }
}
